<script setup>
import { ref } from 'vue';
import { Link, useForm, usePage } from '@inertiajs/vue3';
import LiteAdminLayout from '@/Layouts/LiteAdminLayout.vue';
import Animate from '@/Components/Admin/Animate.vue';
import AltGreenBadge from '@/Components/Admin/Badges/AltGreenBadge.vue';
import Card from '@/Components/Admin/Cards/Card.vue';
import RedCardButton from '@/Components/Admin/Cards/RedCardButton.vue';
import CheckboxInput from '@/Components/Admin/Forms/CheckboxInput.vue';
import FormGroup from '@/Components/Admin/Forms/FormGroup.vue';
import GroupLabel from '@/Components/Admin/Forms/GroupLabel.vue';
import InputLabel from '@/Components/Admin/Forms/InputLabel.vue';
import TextInput from '@/Components/Admin/Forms/TextInput.vue';
import ValidationMessage from '@/Components/Admin/Forms/ValidationMessage.vue';
import HtmlHead from '@/Components/Admin/HtmlHead.vue';
import VerticalLogo from '@/Components/Admin/Logos/VerticalLogo.vue';
import SlimContainer from '@/Components/Admin/SlimContainer.vue';
import DarkText from '@/Components/Admin/Text/DarkText.vue';
import IconRightFromBracket from '@/Components/Icons/IconRightFromBracket.vue';
import route from '@/Helpers/route';

defineOptions({
    layout: (h, page) => h(LiteAdminLayout, () => [page]),
});

defineProps({
    status: String,
});

const page = usePage();

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const showPasswordResetMessage = ref(
    page.props.status === 'Your password has been reset.',
);

const submit = () =>
    form.post(route('login'), {
        onFinish: () => form.reset('password'),
    });
</script>

<template>
    <HtmlHead title="Inloggen" />

    <SlimContainer class="flex h-screen flex-col items-center justify-center">
        <VerticalLogo class="mb-24 max-w-48" />

        <Animate appear>
            <Card class="w-full max-w-128">
                <div class="p-6">
                    <DarkText class="mb-2 text-center font-medium" as="p"
                        >Check je browser URL voordat je doorgaat.</DarkText
                    >
                    <div class="mb-8 flex flex-row justify-center">
                        <AltGreenBadge>https://debestelapp.nl</AltGreenBadge>
                    </div>

                    <Animate appear>
                        <div
                            class="mb-4 rounded-sm bg-green-200 p-3 text-sm font-medium"
                            v-if="showPasswordResetMessage"
                        >
                            Je wachtwoord is succesvol aangepast.
                        </div>
                    </Animate>

                    <form
                        class="grid grid-cols-1 gap-4"
                        @submit.prevent="submit"
                    >
                        <FormGroup>
                            <GroupLabel label="E-mailadres" for="input-email" />

                            <TextInput
                                class="block w-full dark:bg-gray-900!"
                                id="input-email"
                                type="email"
                                required
                                autofocus
                                dusk="input-email"
                                v-model="form.email"
                            />

                            <ValidationMessage :message="form.errors.email" />
                        </FormGroup>

                        <FormGroup>
                            <GroupLabel
                                label="Wachtwoord"
                                for="input-password"
                            />

                            <TextInput
                                class="block w-full dark:bg-gray-900!"
                                id="input-password"
                                type="password"
                                required
                                dusk="input-password"
                                v-model="form.password"
                            />

                            <ValidationMessage
                                :message="form.errors.password"
                            />
                        </FormGroup>

                        <FormGroup>
                            <CheckboxInput
                                id="input-remember"
                                dusk="input-remember"
                                v-model="form.remember"
                            >
                                <InputLabel
                                    label="Ingelogd blijven?"
                                    for="input-remember"
                                />
                            </CheckboxInput>

                            <ValidationMessage
                                :message="form.errors.remember"
                            />
                        </FormGroup>

                        <button class="hidden"></button>
                    </form>

                    <div class="mt-4 flex justify-between">
                        <Link
                            class="text-sm text-gray-500"
                            :href="route('register')"
                            >Nog geen account?</Link
                        >
                        <Link
                            class="text-sm text-gray-500"
                            :href="route('password.request')"
                            >Wachtwoord vergeten?</Link
                        >
                    </div>
                </div>

                <template #footer>
                    <RedCardButton
                        class="w-full"
                        type="submit"
                        @click="submit()"
                        :is-loading="form.processing"
                    >
                        Inloggen als restaurant
                        <IconRightFromBracket data-slot="icon" />
                    </RedCardButton>
                </template>
            </Card>
        </Animate>
    </SlimContainer>
</template>
